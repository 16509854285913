import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Page two" />
    <h1>Hi from the second page</h1>
    <p>Welcome to page 2</p>
    <iframe title="page-2"
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/0572WLjS-SY" 
      frameborder="0" 
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen>
    </iframe>
    <Link to="/page-3/">Go to page 3</Link>
  </Layout>
)

export default SecondPage
